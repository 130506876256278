

  .login {
    background-color: #f2f2f2;
    position: relative;
    margin: 10vh auto;
    padding: 20px 20px 20px;
    width: 440px;
    height: 440px;
   
    border-radius: 3px;
    box-shadow: 0 1px 14px #f2f2f2;
  }
  
  .passwordClass{

    width: 400px;

  }

  .forgotPass{

    font-size: 120%;
    color: blue;
  }


  .contactButton{

    width: 100%;
    background-color: darkcyan;
    border-radius: 20Px;
    margin-top: 0%;
    height: 30px;
  }

  
  .contactButton:focus{

    outline: none;

}



  .login:before {
    content: '';
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    z-index: -1;
    
    border-radius: 4px;
  }
  
  .login h1 {
    margin: -20px -20px 21px;
    line-height: 40px;
    font-size: 15px;
    font-weight: bold;
    color: #555;
    text-align: center;
    text-shadow: 0 1px white;
    background: #f3f3f3;
    border-bottom: 1px solid #cfcfcf;
    border-radius: 3px 3px 0 0;
    background-image: -webkit-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -moz-linear-gradient(top, whiteffd, #eef2f5);
    background-image: -o-linear-gradient(top, whiteffd, #eef2f5);
    background-image: linear-gradient(to bottom, whiteffd, #eef2f5);
    -webkit-box-shadow: 0 1px whitesmoke;
    box-shadow: 0 1px whitesmoke;
  }
  
  .login p {
    margin: 20px 0 0;
  }
  
  .login p:first-child {
    margin-top: 0;
  }
  

  
  .login p.remember_me {
    float: left;
    line-height: 31px;
  }
  
  .login p.remember_me label {
    font-size: 12px;
    color: #777;
    cursor: pointer;
  }
  
  .login p.remember_me input {
    position: relative;
    bottom: 1px;
    margin-right: 4px;
    vertical-align: middle;
  }
  
  .login p.submit {
    text-align: right;
  }
  
  .login-help {
    margin: 20px 0;
    font-size: 11px;
    color: white;
    text-align: center;
    text-shadow: 0 1px #2a85a1;
  }
  
  .login-help a {
    color: #cce7fa;
    text-decoration: none;
  }
  
  .login-help a:hover {
    text-decoration: underline;
  }
  
  :-moz-placeholder {
    color: #c9c9c9 !important;
    font-size: 13px;
  }
  
  ::-webkit-input-placeholder {
    color: #ccc;
    font-size: 13px;
  }
  
  input {
    font-family: 'Lucida Grande', Tahoma, Verdana, sans-serif;
    font-size: 14px;
  }


  .captchaImageLogin{

    margin-left: 0%;
    height: 60%;
    width: 110%;
  }

  .LoginButton{

    float: right;

  }

  .registButton{

    float: left;
  }


/*   
  input[type=text], input[type=password] {
    margin: 5px;
    padding: 0 10px;
    width: 200px;
    height: 34px;
    color: #404040;
    background: white;
    border: 1px solid;
    border-color: #c4c4c4 #d1d1d1 #d4d4d4;
    border-radius: 2px;
    outline: 5px solid #eff4f7;
    -moz-outline-radius: 3px;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);
  }
  
  input[type=text]:focus, input[type=password]:focus {
    border-color: #7dc9e2;
    outline-color: #dceefc;
    outline-offset: 0;
  }
  
  */
/*   
  input[type=submit] {
    padding: 0 18px;
    height: 29px;
    font-size: 12px;
    font-weight: bold;
    color: #527881;
    text-shadow: 0 1px #e3f1f1;
    background: #cde5ef;
    border: 1px solid;
    border-color: #b4ccce #b3c0c8 #9eb9c2;
    border-radius: 16px;
    outline: 0;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    background-image: -webkit-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -moz-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: -o-linear-gradient(top, #edf5f8, #cde5ef);
    background-image: linear-gradient(to bottom, #edf5f8, #cde5ef);
    -webkit-box-shadow: inset 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.15);
  } 
  
  input[type=submit]:active {
    background: #cde5ef;
    border-color: #9eb9c2 #b3c0c8 #b4ccce;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
  } */
  
  .lt-ie9 input[type=text], .lt-ie9 input[type=password] {
    line-height: 34px;
  }

  @media only screen and (max-width:600px){

    .login{
      width:100%;
      height: auto;
      margin-top: -2px;
      /* padding: 0px 2px 0px 2px; */
      margin-left: 2px;
      margin-right:2px;
      border-radius: 8px;
      box-shadow: 0 ;

    }

    
    .body{
      width: 100%;
     
      height: auto;
    }

    .titleLogo{

      width: 250px;
      height: 100px;

    }

    .secondLogo{

     
      width: 100px;
      height: 100px;
    }


    .secondaryLogo .titleLogo{
      line-height:50px;
      max-width: 100%;
    }  
    


     .captchaImageLogin {
       
        
        height: 50px;
        width: 100%;
        margin-bottom: 10%;
        
      
      }


      .catchaCode{

        padding-top: 10%;

      }

      .reFreshCaptcha{
        height:  20px;
        width: 2px;
        cursor:pointer;
      }

      .login input{
        width:100%;
      }

    
      .LoginButton{

        padding-top: 10%;
        float: none;
      }

   
      .registButton{

        padding-top: 10%;
        float: none;
        width: 100%;
      }





  }